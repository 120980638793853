<template>
  <div>
    <b-modal :id="name === 'mandatory' ? 'init-upload-mandatory-modal' : 'init-upload-optional-modal'" hide-footer modal-class="modal-mandatory-init-upload">
      <template #modal-header>
        <b-button
          type="button"
          aria-label="Close"
          variant="outline-light"
          pill
          style="border: 0; font-size: 25px; position: absolute; right: 0"
          @click="!isAccessingCamera &&
            name === 'mandatory'
              ? $bvModal.hide('init-upload-mandatory-modal')
              : $bvModal.hide('init-upload-optional-modal')
            "
        >
          <b-icon icon="x-circle"></b-icon>
        </b-button>
      </template>
      <div class="d-block text-center">
        <div class="upload-option-wrapper">
          <b-overlay
            :show="isAccessingCamera"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
          >
            <div
              class="upload-option upload-camera"
              @click="initImgUploadCamera"
            >
              <div>
                <b-icon class="option-icon" icon="camera-fill"></b-icon>
              </div>
              <div><b>Guided camera mode</b></div>
            </div>
          </b-overlay>

          <div
            class="upload-option upload-file"
            @click="!isAccessingCamera && initImgUploadFile()"
          >
            <div><b-icon class="option-icon" icon="images"></b-icon></div>
            <div><b>Folder</b></div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PhotoUploadOptions",
  data() {
    return {
      name: '',
    }
  },
  props: {
    isAccessingCamera: Boolean,
    imageDeleting: Boolean,
    checkReadonly: Boolean,
  },
  methods: {
    initImgUpload(componentName) {
      if (componentName === 'mandatory') {
        this.name = componentName;
        !this.imageDeleting && !this.checkReadonly && this.$bvModal.show("init-upload-mandatory-modal");
      } else if (componentName === 'optional') {
        this.name = componentName;
        !this.imageDeleting && !this.checkReadonly && this.$bvModal.show("init-upload-optional-modal");
      }
    },
    initImgUploadCamera() {
      this.$emit("initImgUploadCamera");
      this.name === 'mandatory'
        ? this.$bvModal.hide("init-upload-mandatory-modal")
        : this.$bvModal.hide("init-upload-optional-modal")
      ;
    },
    initImgUploadFile() {
      // Access the child component's ref in the parent component
      const mandatoryChildComponent = this.$parent.$children.find(
        (child) =>  child.$options.name === 'FrameItem' && child.$props.frameKey === this.keyFrame
      );

      const optionalChildComponent = this.$parent.$children.find(
        (child) =>  child.$options.name === 'FrameItemOptional' && child.$props.frameKey === this.keyFrame
      );

      if (mandatoryChildComponent) {
        const mandatoryChildRefElement = mandatoryChildComponent.$refs[this.keyFrame];
        if (!this.checkReadonly) {
          mandatoryChildRefElement.click();
          this.$bvModal.hide("init-upload-mandatory-modal");
        }
      } else if (optionalChildComponent) {
        const optionalChildRefElement = optionalChildComponent.$refs[this.keyFrame];
        if (!this.checkReadonly) {
          optionalChildRefElement.click();
          this.$bvModal.hide("init-upload-optional-modal");
        }
      }
    }
  },
  computed: {
    ...mapState({
      keyFrame: (state) => state.photo.keyFrame,
    }),
  },
}
</script>

<style lang="scss">
.modal-mandatory-init-upload {
  .modal-header {
    padding: 10px !important;
    border: 0 !important;
  }
  .modal-dialog .modal-content {
    background-color: rgb(55, 179, 114, 0.9) !important;
    border-radius: 25px !important;
  }
  .modal-body {
    margin: 30px auto;
    padding: 1.4rem !important;
    width: 100%;
    .upload-option-wrapper {
      display: grid;
      grid-template-columns: 60% 40%;
      column-gap: 20px;
      width: 100%;
      justify-content: center;
      .upload-option {
        padding-top: 1em !important;
        padding-bottom: 1em !important;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
      }
      .upload-camera {
        .option-icon {
          color: #05b36d;
          font-size: 60px;
        }
        b {
          color: #05b36d;
        }
      }
      .upload-file {
        .option-icon {
          color: rgb(112, 112, 112);
          font-size: 60px;
        }
        b {
          color: rgb(112, 112, 112);
        }
      }
    }
  }
}
</style>